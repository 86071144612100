<template>
	<div class="d-flex align-center">
		<div v-if="logoUrl == null" class="no-logo"></div>
		<img v-else-if="isOnBoardingFlow" class="ortho-logo-nav" :src="logoUrl" />
		<img
			v-else-if="isForSessionFormation"
			class="ortho-logo-nav cursor-pointer"
			:src="logoUrl"
			@click="directToRedaction"
		/>
		<a v-else href="/">
			<img class="ortho-logo-nav cursor-pointer" :src="logoUrl" />
		</a>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { TopicLogosLocation } from '@/constants/logos.js';

const pathShowDefaultLogo = [
	'login',
	'forget-password',
	'login-sso',
	'login-sso-process',
	'reset-password',
	'register',
];

const pathShowOrthographiqLogo = [
	'to-current-orthographiq-exercise',
	'onboarding',
	'test-positionnement',
	'exercices',
	'unlock-parcours',
	'dashboard/rules',
	'orthographiq-rules-training',
	'orthographiq-assistant',
];

export default {
	name: 'OrthographiqLogo',
	data() {
		return {
			isOnBoardingFlow: false,
			logoUrl: null,
			allTopics: null,
		};
	},
	computed: {
		...mapGetters('profile', { formationProgress: 'formationProgress' }),
		isForSessionFormation() {
			if (this.$router.currentRoute.name === 'TestHomePage' && this.$route.query.topicSlug !== 'orthographe') {
				return true;
			}
			if (this.formationProgress == null) return false;
			return this.formationProgress.formation.for_session;
		},
	},
	methods: {
		async directToRedaction() {
			const _formationId = this.$route.query.formationId || this.formationProgress.formation._id;
			await this.$store.dispatch('profile/formationProgress', { idFormation: _formationId });

			if (this.$router.currentRoute.name === 'TestHomePage') {
				this.$router.push({
					name: 'Topic Dashboard Index',
					params: { topicSlug: this.$route.query.topicSlug, formationId: _formationId },
				});
				return;
			}
			this.$router.push({
				name: 'Topic Dashboard Index',
				params: { topicSlug: this.formationProgress.config.topic_id.slug, formationId: _formationId },
			});
		},
		isCurrentPathInPathArr(_pathArr) {
			const path = window.location.pathname;
			for (let item of _pathArr) {
				if (path.includes(item)) return true;
			}
			return false;
		},
		findTopicLogoBySlug(slug) {
			if (this.allTopics == null) return null;
			return this.allTopics.find((topic) => topic.slug === slug).logo_url;
		},
		findTopicLogoByTopicId(topicId) {
			if (this.allTopics == null) return null;
			return this.allTopics.find((topic) => topic._id === topicId).logo_url;
		},
		async chooseLogo() {
			try {
				if (this.allTopics == null) {
					this.allTopics = await this.$store.dispatch('topics/fetchAllTopics');
				}
				/** Specific case that logo doesnt change */
				if (this.isCurrentPathInPathArr(pathShowDefaultLogo) == true)
					return (this.logoUrl = TopicLogosLocation.default);
				if (
					this.isCurrentPathInPathArr(pathShowOrthographiqLogo) &&
					!this.isCurrentPathInPathArr(['onboarding-topic'])
				) {
					return (this.logoUrl = TopicLogosLocation.orthographiq);
				}

				const path = window.location.pathname;
				/** Case if url have topic slug */
				if (path.includes('redaction')) return (this.logoUrl = TopicLogosLocation.redaciq);
				if (path.includes('cyber')) return (this.logoUrl = TopicLogosLocation.cyberiq);
				// .... When have more topic will add more slug here

				/** Case at page Do Test: display logo depends on topic of test bank */
				if (path.includes('do-test')) {
					const testId = path.split('/')[2];

					const { testBank } = await this.$store.dispatch('tests/fetchTest', { testId });
					if (testBank == null || testBank.topic_id == null) return (this.logoUrl = TopicLogosLocation.default);
					this.logoUrl = this.findTopicLogoByTopicId(testBank.topic_id);
					return;
				}

				/** Case test result page && position test result page */
				if (path.includes('test-result') || path.includes('position-test-result')) {
					const testScoreId = path.split('/')[2];
					const testScore = await this.$store.dispatch('testScores/fetchTestScoreById', { testScoreId });
					if (testScore == null || testScore.topic_id == null) return (this.logoUrl = TopicLogosLocation.default);
					this.logoUrl = this.findTopicLogoByTopicId(testScore.topic_id);
					return;
				}

				/** Case tests home page */
				if (path.includes('tests-home')) {
					const urlParams = new URLSearchParams(window.location.search);
					const topicSlug = urlParams.get('topicSlug');
					if (topicSlug == null) return (this.logoUrl = TopicLogosLocation.default);
					this.logoUrl = this.findTopicLogoBySlug(topicSlug);
					return;
				}

				/** Cases when path doesnt have topic slug but have formation progress as: session page */
				if (this.formationProgress != null) return (this.logoUrl = this.formationProgress.config.topic_id.logo_url);

				return (this.logoUrl = TopicLogosLocation.default);
			} catch (error) {
				console.error(error);
				return (this.logoUrl = TopicLogosLocation.default);
			}
		},
	},
	mounted() {
		this.chooseLogo();
	},
	watch: {
		$route() {
			this.chooseLogo(); // Have to watch route because when first load currentRoute.name = null. When currentRoute.name updated component dont auto update

			if (this.$router.currentRoute.name === 'Onboarding') this.isOnBoardingFlow = true;
			else if (this.$router.currentRoute.name === 'TestPositionnement') this.isOnBoardingFlow = true;
			else this.isOnBoardingFlow = false;
		},
		formationProgress() {
			this.chooseLogo();
		},
	},
};
</script>

<style lang="scss" scoped>
.no-logo {
	height: 64px;
}
</style>
