import axios from 'axios';

const state = () => ({
	formationData: {},
});

const getters = {
	formationData: (state) => {
		return state.formationData;
	},
};
const mutations = {
	formationData(state, infos) {
		state.formationData = infos;
	},
};
const actions = {
	async getFormationData(store, { idFormation }) {
		const res = await axios.get('/api/formation/' + idFormation);
		store.commit('formationData', res.data.formationData);
		return res.data.formationData;
	},
	async getAllFormationsOfUser() {
		if (localStorage.getItem('token') != null)
			axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('token');
		const res = await axios.get('/api/formation');
		return res.data.formations;
	},
	async fetchFormationsByUserId(store, { userId }) {
		const res = await axios.get(`/api/users/${userId}/formations`);
		return res.data.formations;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
