<template>
	<div class="d-flex align-center nav-container">
		<div class="logo-wrapper">
			<OrthographiqLogo />
		</div>
		<span v-if="showEnterpriseLogo" class="separator">X</span>
		<div v-if="showEnterpriseLogo" class="logo-wrapper">
			<img class="enterprise-logo-nav" :src="formationProgress.user.entreprise_id.logo" />
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import OrthographiqLogo from './OrthographiqLogo.vue';

export default {
	name: 'LogosColumn',
	components: { OrthographiqLogo },
	computed: {
		...mapGetters('profile', { formationProgress: 'formationProgress' }),
		showEnterpriseLogo() {
			return (
				this.formationProgress &&
				this.formationProgress.user.entreprise_id &&
				this.formationProgress.user.entreprise_id.logo != '' &&
				this.formationProgress.user.entreprise_id.logo != null
			);
		},
	},
};
</script>

<style lang="scss" scoped>
.nav-container {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}

.logo-wrapper {
	display: flex;
	align-items: center;
	height: 60px;
}

.logo-wrapper img.enterprise-logo-nav {
	object-fit: contain;
}

.separator {
	font-weight: 600;
	padding: 0 3px;
	display: flex;
	align-items: center;
	height: 60px;
}

@media screen and (max-width: 600px) {
	.nav-container {
		padding-left: 0px !important;
	}
	.header-name {
		display: none;
	}
}
</style>
