import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
	{
		path: '/login',
		name: 'Login',
		component: () => import('../views/pages/Login.vue'),
		meta: { header: 'Connexion' },
		beforeEnter: (to, from, next) => {
			if (localStorage.getItem('token') != null) next({ name: 'Dashboard' });
			else next();
		},
	},
	{
		path: '/forget-password',
		name: 'ForgetPassword',
		component: () => import('../views/pages/ForgetPassword.vue'),
		meta: { header: 'Connexion' },
	},
	{
		path: '/login-sso',
		name: 'LoginSSO',
		component: () => import('../views/pages/LoginSSO.vue'),
		meta: { header: 'Connexion' },
		beforeEnter: (to, from, next) => {
			if (localStorage.getItem('token') != null) next({ name: 'Dashboard' });
			else next();
		},
	},
	{
		path: '/login-sso-process',
		name: 'LoginSSOProcess',
		component: () => import('../views/pages/LoginSSOProcess.vue'),
		meta: { header: 'Connexion' },
		beforeEnter: (to, from, next) => {
			if (localStorage.getItem('token') != null) next({ name: 'Dashboard' });
			else next();
		},
	},
	{
		path: '/orthographiq-assistant/:formationId',
		name: 'Orthographiq Assistant',
		component: () => import('../views/pages/OrthographiqAssistant.vue'),
		meta: { header: 'Assistant' },
		beforeEnter: (to, from, next) => {
			if (localStorage.getItem('token') != null) next();
			else next({ name: 'Login' });
		},
	},
	{
		path: '/to-current-orthographiq-exercise',
		name: 'To Current Orthographiq Exercise',
		component: () => import('../views/pages/ToCurrentOrthographiqExercise.vue'),
		meta: { header: '' },
		beforeEnter: (to, from, next) => {
			if (localStorage.getItem('token') == null) next({ name: 'Login' });
			else next();
		},
	},
	{
		path: '/dashboard/topic/:topicSlug/:formationId',
		name: 'Topic Dashboard',
		component: () => import('../views/pages/TopicFormationDashboard.vue'),
		beforeEnter: (to, from, next) => {
			if (localStorage.getItem('token') != null) next();
			else next({ name: 'Login' });
		},
		children: [
			{
				path: 'index',
				name: 'Topic Dashboard Index',
				component: () => import('../views/pages/TopicFormationDashBoardIndex.vue'),
			},
			{
				path: 'leaderboard/weekly',
				name: 'Topic Leaderboard Weekly',
				component: () => import('../views/leaderboard/WeeklyLeaderBoardPage.vue'),
			},
			{
				path: 'assistant',
				name: 'TopicAssistant',
				component: () => import('../views/pages/TopicAssistant.vue'),
			},
			{
				path: ':parcoursId',
				name: 'Topic Dashboard Parcours Detail',
				component: () => import('../views/pages/DynamicParcoursDetail.vue'),
			},
			{
				path: ':idParcours/:idCategory',
				name: 'Topic Dashboard Chapter Detail',
				component: () => import('../views/pages/ChapterSessionDetail.vue'),
			},
		],
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
		children: [
			{
				path: 'profile',
				name: 'Profile',
				component: () => import(/* webpackChunkName: "rules" */ '../views/Profile.vue'),
				meta: { header: 'Espace Personnel' },
				children: [
					{
						path: '',
						name: 'Profile Index',
						component: () => import('../components/dashboard/profile/profileIndex/ProfileIndex.vue'),
					},
					{
						path: 'leaderboard/orthographiq/weekly',
						name: 'Orthographiq Leaderboard Weekly',
						component: () => import('../views/leaderboard/WeeklyLeaderBoardPage.vue'),
					},
					{
						path: ':idParcours',
						name: 'Parcours Detail',
						component: () => import('../components/dashboard/profile/parcours/ParcoursDetail.vue'),
					},
					{
						path: ':idParcours/:idCategory',
						name: 'Category Detail',
						component: () => import('../components/dashboard/profile/chapter/ChapterDetail.vue'),
					},
				],
			},
			{
				path: 'rules',
				name: 'Rules',
				component: () => import(/* webpackChunkName: "rules" */ '../views/pages/Rules.vue'),
				meta: { header: 'Espace Règles' },
			},
			{
				path: 'parametres',
				name: 'Parametres',
				component: () => import('../views/Parametres.vue'),
				meta: { header: 'Paramètres' },
			},
		],
		beforeEnter: (to, from, next) => {
			if (localStorage.getItem('token') != null) next();
			else next({ name: 'Login' });
		},
	},
	{
		path: '/reset-password/:idUser',
		name: 'ResetPassword',
		component: () => import('../views/pages/ResetPassword.vue'),
	},
	{
		path: '/onboarding/:idUser',
		name: 'Onboarding',
		component: () => import(/* webpackChunkName: "onboarding" */ '../views/pages/Onboarding.vue'),
	},
	{
		path: '/onboarding-topic/:topicSlug/:formationId',
		name: 'OnboardingFormationTopic',
		component: () => import('../views/pages/OnboardingFormationTopic.vue'),
	},
	{
		path: '/test-positionnement/:idUser/:idFormation',
		name: 'TestPositionnement',
		component: () => import(/* webpackChunkName: "test-positionnement" */ '../views/pages/TestPositionnementPage.vue'),
		beforeEnter: (to, from, next) => {
			if (from.name == 'Onboarding') to.meta.from = 'Onboarding';
			next();
		},
	},
	{
		path: '/exercices/:idFormation/:idExercice',
		name: 'Exercice',
		component: () => import(/* webpackChunkName: "exercice" */ '../views/pages/Exercice.vue'),
	},
	{
		path: '/story/:idFormation/:idExercice/:idStory',
		name: 'Story',
		component: () => import(/* webpackChunkName: "story" */ '../views/pages/Story.vue'),
	},
	{
		path: '/orthographiq-rules-training/:idFormation/:idRules',
		name: 'Orthographiq Rules Training',
		component: () => import(/* webpackChunkName: "story" */ '../views/pages/RulesTraining.vue'),
	},
	{
		path: '/report', // Report of orthographiq
		name: 'Report',
		redirect: { name: 'PageNotFound' },
		component: () => import(/* webpackChunkName: "report" */ '../views/reports/Index.vue'),
		children: [
			{
				path: 'category/:idFormation/:idCategory',
				name: 'Report Category',
				component: () => import(/* webpackChunkName: "report-category" */ '../views/reports/Category.vue'),
			},
			{
				path: 'parcours/:idFormation/:idParcours',
				name: 'Report Parcours',
				component: () => import('../views/reports/ReportParcours.vue'),
			},
			{
				path: 'end-formation/:idFormation/:idParcours',
				name: 'Report Formation',
				component: () => import('../views/reports/ReportFormation.vue'),
			},
		],
	},
	{
		path: '/report-session-parcours',
		name: 'Report Session Parcours Index',
		redirect: { name: 'PageNotFound' },
		component: () => import('../views/reports/Index.vue'),
		children: [
			{
				path: 'category/:formationId/:categoryId',
				name: 'Chapter Report',
				component: () => import('../views/reports/ReportChapterCategory.vue'),
			},
			{
				path: 'parcours/:idFormation/:idParcours',
				name: 'Parcours Report',
				component: () => import('../views/reports/ParcoursReport.vue'),
			},
			{
				path: 'end-formation/:idFormation/:idParcours',
				name: 'End Formation Report',
				component: () => import('../views/reports/FormationReport.vue'),
			},
		],
	},
	{
		path: '/',
		name: 'Home',
		beforeEnter: (to, from, next) => {
			next({ name: 'Dashboard' });
		},
	},
	{
		path: '/register',
		name: 'Register',
		component: () => import('../views/pages/Register.vue'),
		meta: { header: 'Inscription' },
		beforeEnter: (to, from, next) => {
			if (localStorage.getItem('token') != null) next({ name: 'Dashboard' });
			else next();
		},
	},
	{
		path: '/logout',
		name: 'Logout',
		component: () => import('../views/pages/Logout.vue'),
	},
	{
		path: '/unlock-parcours/:idFormation',
		name: 'ChooseParcousToUnlock',
		component: () => import('../views/ChooseParcousToUnlock.vue'),
	},
	{
		path: '/sessions/:formationId/:sessionId',
		name: 'SessionDetailPage',
		component: () => import('../views/pages/SessionDetail.vue'),
	},
	{
		path: '/sessions/:formationId/:sessionId/result',
		name: 'SessionResultPage',
		component: () => import('../views/pages/SessionResult.vue'),
	},
	{
		path: '/tracks/:formationId/:sessionId/:sessionBlockId/:trackId',
		name: 'TrackPage',
		component: () => import('../views/pages/TrackPage.vue'),
	},
	{
		path: '/tests-home',
		name: 'TestHomePage',
		component: () => import('../views/pages/TestHomePage.vue'),
		beforeEnter: (to, from, next) => {
			if (localStorage.getItem('token') != null) next();
			else next({ name: 'Login' });
		},
	},
	{
		path: '/do-test/:testId/:userId',
		name: 'DoTestPage',
		component: () => import('../views/pages/DoTestPage.vue'),
	},
	{
		path: '/test-result/:testScoreId',
		name: 'TestResultPage',
		component: () => import('../views/pages/TestResultPage.vue'),
	},
	{
		path: '/position-test-result/:testScoreId',
		name: 'PositionTestResultPage',
		component: () => import('../views/pages/PositionTestResultPage.vue'),
	},
	{
		path: '*',
		name: 'PageNotFound',
		component: () => import('../views/pages/PageNotFound.vue'),
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;
