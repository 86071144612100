import { render, staticRenderFns } from "./TopicDropdownMobile.vue?vue&type=template&id=14a90e61&scoped=true&"
import script from "./TopicDropdownMobile.vue?vue&type=script&lang=js&"
export * from "./TopicDropdownMobile.vue?vue&type=script&lang=js&"
import style0 from "./TopicDropdownMobile.vue?vue&type=style&index=0&id=14a90e61&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14a90e61",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
