<template>
	<div v-if="formationProgress" class="topic-dropdown-mobile pb-3" style="border-bottom: 1px solid #989fa9">
		<div v-for="(topic, index) in topics" :key="index" class="navBarLink" :class="{ selected: isSeleted(topic._id) }">
			<div v-if="checkTopicReady(topic)" @click="selectActiveItem(topic)">
				<span>{{ topic.name }}</span>
			</div>
			<div class="lock-item" v-else @click="selectLockedItem(topic)">
				<span>{{ topic.name }}</span>
				<v-icon size="20px" color="#989fa9">mdi-lock-outline</v-icon>
			</div>
		</div>
		<TopicIntroductionModal v-if="showModal" :topic="modalTopic" :closeModalInParent="closeModal" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import TopicIntroductionModal from '@/components/TopicIntroductionModal/TopicIntroductionModal.vue';
import { isPastGivenTime } from '@/utils/time.js';

export default {
	name: 'TopicDropdownMobile',
	components: { TopicIntroductionModal },
	props: {
		closeMenu: Function,
	},
	computed: {
		...mapGetters('profile', { formationProgress: 'formationProgress' }),
	},
	data: () => ({
		topics: [],
		selectedTopic: null,
		modalTopic: null,
		showModal: false,
	}),
	methods: {
		checkTopicReady(transformedTopic) {
			return transformedTopic.formationId && isPastGivenTime(transformedTopic.beginDate);
		},
		closeModal() {
			this.modalTopic = null;
			this.showModal = false;
		},
		directToDashboardOfFormation(topicSlug, formationId) {
			this.$router.push({
				name: 'Topic Dashboard Index',
				params: { topicSlug: topicSlug, formationId: formationId },
				// query: { directTo: 'parcours-detail' }, Comment out this query to not redirect to categories list.
			});
		},
		directToOrthograph() {
			this.$router.push({ name: 'Dashboard' });
		},
		async directToFormation(selectedTopic) {
			await this.$store.dispatch('profile/formationProgress', { idFormation: selectedTopic.formationId });

			if (selectedTopic.forSession === true)
				return this.directToDashboardOfFormation(selectedTopic.slug, selectedTopic.formationId);
			this.directToOrthograph(this.formationProgress);
		},
		selectLockedItem(topic) {
			this.modalTopic = topic;
			this.showModal = true;
		},
		selectActiveItem(topic) {
			this.selectedTopic = topic;
			this.$store.dispatch('appState/triggerLoading');
			this.directToFormation(topic);
		},
		async initGetAllTopics(userId) {
			if (userId == null) return;
			const transformedTopic = await this.$store.dispatch('topics/getAllTopics', { userId });
			this.topics = transformedTopic; // Transformed topic: if user has this topic, it will have formationId and formationConfigId.

			const topicOfThisFormation = transformedTopic.find(
				(topic) => topic.formationId === this.formationProgress.formation._id
			);
			this.selectedTopic = topicOfThisFormation;
		},
		isSeleted(topicId) {
			return topicId === this.selectedTopic._id;
		},
	},
	async mounted() {
		if (this.formationProgress == null) return;
		await this.initGetAllTopics(this.formationProgress.user._id);
	},
	watch: {
		formationProgress() {
			if (this.formationProgress == null) return;
			this.initGetAllTopics(this.formationProgress.user._id);
		},
	},
};
</script>

<style lang="scss" scoped>
.topic-dropdown-mobile {
	display: flex;
	flex-direction: column;
	gap: 8px;

	.navBarLink {
		text-transform: capitalize;
		font-size: 15px;
		cursor: pointer;
		font-family: 'Roboto', sans-serif;
		color: #989fa9;
		display: flex;
		justify-content: space-between;
		width: 100%;
		&:hover {
			color: #686bdf;
		}

		.lock-item {
			display: flex;
			gap: 16px;
			align-items: center;
			justify-content: space-between;
			flex: 1;
			div {
				flex: 1;
			}
		}
	}

	.selected {
		color: #686bdf;
		cursor: default;
		pointer-events: none;
	}
}
</style>
