import axios from 'axios';
import { isPastGivenTime } from '@/utils/time.js';

const state = () => ({
	allTopics: [],
});

const getters = {
	allTopics: (state) => {
		return state.allTopics;
	},
};
const mutations = {
	allTopics(state, transformedTopics) {
		state.allTopics = transformedTopics;
	},
};
const actions = {
	async getAllTopics(store, { userId }) {
		const res = await axios.get('/api/topics/findByUserId/' + userId);
		const { data } = res.data;
		let transformedTopics = data.allTopics.map((topic) => {
			const formationConfig = data.formationConfigs.find((f) => f.topic_id === topic._id);
			if (formationConfig == null) return topic;
			topic.formationId = formationConfig.formation_id;
			topic.formationConfigId = formationConfig._id;
			topic.forSession = formationConfig.forSession;
			topic.beginDate = formationConfig.begin_date;
			return topic;
		}); // Transformed topic: if user has this topic, it will have formationId and formationConfigId.

		// Sort topic: [open topic, lock topic];
		const openOrthographTopic = transformedTopics.filter(
			(t) => t.formationId && t.slug === 'orthographe' && isPastGivenTime(t.beginDate)
		);
		const openTopicsNotIncludeOrthograph = transformedTopics.filter(
			(t) => t.formationId && t.slug !== 'orthographe' && isPastGivenTime(t.beginDate)
		);
		const lockedOrthograph = transformedTopics.filter(
			(t) =>
				(t.slug === 'orthographe' && t.formationId == null) ||
				(t.slug === 'orthographe' && !isPastGivenTime(t.beginDate))
		);
		const lockedTopicsNotIncludeOrthograph = transformedTopics.filter(
			(t) =>
				(t.slug !== 'orthographe' && t.formationId == null) ||
				(t.slug !== 'orthographe' && !isPastGivenTime(t.beginDate))
		);
		transformedTopics = [
			...openOrthographTopic,
			...openTopicsNotIncludeOrthograph,
			...lockedOrthograph,
			...lockedTopicsNotIncludeOrthograph,
		];
		store.commit('allTopics', transformedTopics);
		return transformedTopics;
	},
	async fetchTopicById(store, { topicId }) {
		const res = await axios.get('/api/topics/' + topicId);
		return res.data;
	},
	async fetchTopicBySlug(store, { slug }) {
		const res = await axios.get('/api/topics/slug/' + slug);
		return res.data;
	},
	async fetchAllTopics() {
		const res = await axios.get('/api/topics');
		return res.data.data.topics;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
