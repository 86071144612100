<template>
	<div class="text-center">
		<v-dialog
			v-model="dialog"
			persistent
			:width="modalWidth"
			:fullscreen="belowDesktop"
			content-class="topic-introduction-modal"
		>
			<v-card v-if="modalContent" class="pa-6 card-container">
				<div class="close-action" :class="[belowDesktop && 'pa-4']">
					<v-btn icon @click="close">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</div>
				<div class="card-title text-center">
					<h5 class="ortho-text-gray">Espace verrouillé</h5>
					<p>{{ unlockSetence(topic) }}</p>
				</div>

				<div class="card-body">
					<div v-if="modalContent.videoLink" class="left-col d-flex align-center w-100">
						<VideoResource :resourceItem="{ url: modalContent.videoLink }" />
					</div>
					<div v-else-if="modalContent.pictureLink" class="left-col d-flex align-center w-100">
						<img class="picture-resource" :src="modalContent.pictureLink" />
					</div>
					<div v-else class="left-col d-flex align-center w-100">
						<img
							class="picture-resource"
							src="https://website-picture-orthographiq.s3.eu-central-1.amazonaws.com/img/cover+image+topic/noVideoOrImg.webp"
						/>
					</div>

					<div class="right-col d-flex align-center">
						<div v-if="!belowDesktop" class="stroke"></div>
						<div>
							<h6 class="text-center title">
								<b>{{ modalContent.title }}</b>
							</h6>
							<div>
								<ul>
									<li v-for="(bullet, index) in modalContent.bulletPoints" :key="index" class="bullet-point">
										{{ bullet }}
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div v-if="isTopicUnlocked(topic) == false" class="text-center mt-5" :class="[belowDesktop && 'pa-4']">
					<v-btn
						depressed
						class="black white--text cta-btn"
						target="_blank"
						href="https://meetings-eu1.hubspot.com/rendez-vous-csm?uuid=2e0446cd-e95e-42a1-b311-a21d2b36ba22?utm_source=application&utm_medium=dashboard&utm_campaign=ask-more-info-topic"
					>
						Debloquer la formation
					</v-btn>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { getMonthInFrench } from '@/utils/time.js';
import { setZindexByClassNames } from '@/utils/helper.js';
import VideoResource from '../supportResources/VideoResource.vue';

const DataForOrthographiqTopic = {
	videoLink: 'https://www.youtube.com/watch?v=Zl_BSM5Dh2g',
	title: 'Maîtrises enfin les règles d’orthographe facilement',
	bulletPoints: [
		'🔎 Une sélection des règles d’orthographe les plus importantes.',
		'✨ Sur-mesure : des exercices adaptés à ton niveau pour des progrès rapides et efficaces.',
		'🔥 Challenges : chaque exercice te fait gagner des points pour grimper dans le classement.',
		'💡 Tout au même endroit : retrouves facilement toutes les règles apprises pour réviser à volonté.',
	],
};

const DataForRedactionTopic = {
	videoLink: 'https://www.youtube.com/watch?v=D5Jh2ES6SFo&ab_channel=OrthographIQ',
	title: 'Améliore tes écrits professionnels',
	bulletPoints: [
		'🆕 De nouveaux formats de question comme l’expression libre, la réponse à un email ou encore la rédaction d’un rapport de réunion',
		'📝 Écris et soit corrigé instantanément grâce à notre intelligence artificielle',
		'✨ Des leçons interactives avec des fiches téléchargeables et des vidéos pour comprendre toutes nos astuces',
		'🧠 Des sessions complètes de 30 min pour écrire comme un pro',
	],
};

const DataForCyberIQTopic = {
	pictureLink: 'https://website-picture-orthographiq.s3.eu-central-1.amazonaws.com/img/cover+image+topic/cyberIQ.webp',
	title: 'Maîtrise les fondamentaux de la cybersécurité',
	bulletPoints: [
		'🔒 Apprends à identifier les cyberattaques et protège tes données efficacement',
		'✨ Des leçons interactives avec des fiches téléchargeables et des vidéos pour comprendre les meilleures pratiques',
		'🧠 Des sessions complètes de 30 min pour sécuriser tes usages numériques comme un expert',
	],
};

export default {
	name: 'TopicIntroductionModal',
	props: { topic: Object, closeModalInParent: Function },
	components: { VideoResource },
	data() {
		return { dialog: true, modalContent: null };
	},
	computed: {
		resourceItem() {
			return { url: 'https://www.youtube.com/watch?v=D5Jh2ES6SFo&ab_channel=OrthographIQ' };
		},
		modalWidth() {
			if (window.innerWidth > 1904) return '50%';
			if (window.innerWidth >= 960) return '70%';
			return '100%';
		},
		belowDesktop() {
			return window.innerWidth <= 648;
		},
	},
	methods: {
		isTopicUnlocked(topic) {
			if (topic.formationId == null) return false;
		},
		unlockSetence(topic) {
			if (topic.formationId == null) return 'Vous n’avez pas accès à cette formation';
			return `Cette formation se débloque le ${new Date(topic.beginDate).getDate()} ${getMonthInFrench(
				topic.beginDate
			)}`;
		},
		close() {
			this.dialog = false;
			this.closeModalInParent();
		},
		setNavBarZIndex(zindexNumber) {
			const routeNames = ['Orthographiq Assistant', 'Rules'];
			if (routeNames.includes(this.$router.currentRoute.name)) setZindexByClassNames(['navbar'], zindexNumber);
		},
	},
	created() {
		if (this.topic.slug === 'orthographe') this.modalContent = DataForOrthographiqTopic;
		else if (this.topic.slug === 'redaction') this.modalContent = DataForRedactionTopic;
		else if (this.topic.slug === 'cyber') this.modalContent = DataForCyberIQTopic;
	},
	mounted() {
		this.setNavBarZIndex(0);
	},
	destroyed() {
		this.setNavBarZIndex(210);
	},
};
</script>

<style scoped lang="scss">
.topic-introduction-modal {
	overflow-x: hidden;

	ul {
		list-style-type: none;
		padding-left: 0;
	}

	.picture-resource {
		width: 100%;
		border-radius: 4px;
	}

	.title {
		font-size: 20px;
		margin-bottom: 10px;
	}

	.stroke {
		background-color: lightgrey;
		width: 4px;
		height: calc(100% - 50px);
		margin-right: 30px;
	}

	.close-action {
		position: absolute;
		right: 8px;
		top: 8px;
	}

	.card-title {
		h5 {
			font-size: 20px;
		}
		p {
			font-size: 1.3rem;
			font-weight: bold;
			margin-bottom: 30px;
		}
	}

	.card-body {
		display: flex;
		width: 100%;

		.left-col {
			margin-right: 1rem;
		}
		.right-col {
			padding-left: 20px;
		}
	}

	.image {
		width: 300px;
		height: 250px;
	}

	.separator {
		margin: auto 0;
		width: 4px;
		height: 230px;
		background-color: black;
	}

	.bullet-point {
		margin-bottom: 10px;
	}

	.cta-btn {
		text-transform: none;
		padding: 0 40px;
		height: 50px;
		font-size: 1.1rem;
	}

	@media screen and (max-width: 648px) {
		.close-action {
			right: 0px;
			top: 0px;
		}

		.card-title {
			margin-top: 48px;
			padding: 0 16px;
			text-align: center;

			h5,
			p {
				text-align: center;
			}
		}

		.card-body {
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 0 10px;
			text-align: center;

			.left-col {
				margin-right: 0 !important;
				margin-bottom: 1rem;
				width: 100%;
				display: flex;
				justify-content: center;
			}

			.right-col {
				padding-left: 0;
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;

				ul {
					width: 100%;
					text-align: left;
					padding: 0 10px;
				}
			}
		}

		&.v-dialog {
			margin: 0;

			.v-card {
				width: 100%;
				max-width: 100%;
				height: 100%;
				overflow-x: hidden;
			}
		}
	}
}
</style>
