<template>
	<v-toolbar-items v-if="show" cols="6" md="6" id="menu-nav-mobile">
		<div class="burger-container">
			<img src="@/assets/icons/open-menu.svg" alt="menu" style="width: 25px" @click="openMenu = !openMenu" />
			<v-list v-if="openMenu == true && currentRoute.name != 'Login'">
				<div class="d-flex flex-column">
					<div class="nav-menu rounded shadow-blue-not-openned">
						<TopicDropdownMobile v-if="$route.path.includes('dashboard/topic')" :closeMenu="closeMenu" />
						<router-link
							:to="{ name: 'Topic Dashboard Index', params: { topicSlug: topicSlug, formationId: formationId } }"
							:class="{ purpleColor: currentRoute.name == 'Profile' }"
						>
							<span @click="closeMenu">
								<span @click="onDashboardProfile()" class="navBarLink">Espace Personnel</span>
							</span>
						</router-link>
						<router-link
							v-if="topicSlug && topicSlug !== 'cyber' && formationId"
							:to="{ name: 'TopicAssistant', params: { topicSlug: topicSlug, formationId: formationId } }"
							:class="{ purpleColor: currentRoute.name == 'TopicAssistant' }"
						>
							<span @click="closeMenu">
								<span class="navBarLink">Assistant</span>
							</span>
						</router-link>
						<router-link
							:to="{ name: 'TestHomePage', query: { topicSlug: this.topicSlug, formationId: this.formationId } }"
							:class="{ purpleColor: currentRoute.name == 'TestHomePage' }"
						>
							<span @click="closeMenu">
								<TestHomePageNavItem />
							</span>
						</router-link>
					</div>
				</div>
			</v-list>
		</div>
	</v-toolbar-items>
</template>

<script>
import { mapGetters } from 'vuex';
import { showNavBar } from '@/utils/navbar.js';
import MixPanel from '@/utils/mixpanel.js';
import TopicDropdownMobile from './TopicDropdownMobile';
import TestHomePageNavItem from '@/components/navbar/TestHomePageNavItem.vue';

export default {
	name: 'SessionParcoursNavItemsMobile',
	components: { TopicDropdownMobile, TestHomePageNavItem },
	props: { currentRoute: Object },
	computed: {
		...mapGetters('profile', { formationProgress: 'formationProgress' }),
		show() {
			return showNavBar(this.currentRoute.name);
		},
		formationId() {
			if (this.currentRoute.query.formationId) return this.currentRoute.query.formationId; // Test home page
			if (this.formationProgress == null) return '';
			return this.formationProgress.formation._id;
		},
		topicSlug() {
			if (this.currentRoute.query.topicSlug) return this.currentRoute.query.topicSlug; // Test home page
			if (this.formationProgress == null) return '';
			return this.formationProgress.config.topic_id.slug;
		},
	},
	data: () => {
		return { openMenu: false };
	},
	methods: {
		onDashboardProfile() {
			MixPanel.trackToProfile;
		},
		closeMenu() {
			this.openMenu = false;
		},
	},
};
</script>

<style scoped lang="scss">
#menu-nav-mobile {
	.navBarLink {
		text-transform: capitalize;
		font-size: 15px;
		cursor: pointer;
		font-family: 'Roboto', sans-serif;
		color: #989fa9;
		&:hover {
			color: #686bdf !important;
		}
	}

	.purpleColor {
		color: #686bdf !important;
	}

	.burger-container {
		display: flex;
		.nav-menu {
			min-width: 180px;
			display: flex;
			flex-direction: column;
			gap: 8px;
			background-color: white;
			padding: 16px;
			position: absolute;
			right: 16px;
			top: 60px;
		}
	}
}
</style>
