<template>
	<v-toolbar-items v-if="show" cols="6" md="6" id="menu-nav-mobile">
		<div class="burger-container">
			<img src="@/assets/icons/open-menu.svg" alt="menu" style="width: 25px" @click="openMenu = !openMenu" />
			<v-list v-if="openMenu === true">
				<div v-if="currentRoute.name !== 'Login'">
					<div class="nav-menu rounded shadow-blue-not-openned">
						<TopicDropdownMobile :closeMenu="closeMenu" v-if="$route.path.includes('dashboard/profile')" />
						<v-list-item class="d-flex flex-column px-0 pt-3">
							<v-list-item-title>
								<a
									href="/dashboard/profile"
									:class="{ purpleColor: currentRoute.name == 'Profile' }"
									@click="onDashboardProfile()"
								>
									<span @click="openMenu = false">Espace personnel</span>
								</a>
							</v-list-item-title>
							<v-list-item-title>
								<router-link :to="{ name: 'Rules' }" :class="{ purpleColor: currentRoute.name == 'Rules' }">
									<span @click="openMenu = false"><span @click="onDashboardRules()">Espace règles</span></span>
								</router-link>
							</v-list-item-title>
							<v-list-item-title v-if="formationId">
								<router-link
									:to="{ name: 'Orthographiq Assistant', params: { formationId: formationId } }"
									:class="{ purpleColor: currentRoute.name == 'Orthographiq Assistant' }"
								>
									<span @click="openMenu = false"><span @click="onDashboardAssistant()">Assistant</span></span>
								</router-link>
							</v-list-item-title>
							<v-list-item-title class="pt-2">
								<router-link
									:to="{ name: 'TestHomePage', query: { topicSlug: 'orthographe', formationId: formationId } }"
									:class="{ purpleColor: currentRoute.name == 'TestHomePage' }"
								>
									<TestHomePageNavItem @click="openMenu = false" />
								</router-link>
							</v-list-item-title>
						</v-list-item>
					</div>
				</div>
			</v-list>
		</div>
	</v-toolbar-items>
</template>

<script>
import { showNavBar } from '@/utils/navbar.js';
import TopicDropdownMobile from './TopicDropdownMobile';
import MixPanel from '@/utils/mixpanel.js';
import TestHomePageNavItem from './TestHomePageNavItem.vue';

export default {
	name: 'OrthographNavItemsMobile',
	components: { TopicDropdownMobile, TestHomePageNavItem },
	props: {
		currentRoute: Object,
		formationId: String,
		toogleMobileMenu: Function,
	},
	computed: {
		show() {
			return showNavBar(this.currentRoute.name);
		},
	},
	data: () => {
		return {
			openMenu: false,
		};
	},
	methods: {
		closeMenu() {
			this.openMenu = false;
		},
		onDashboardProfile() {
			MixPanel.trackToProfile;
		},
		onDashboardRules() {
			MixPanel.trackToRule;
		},
		onDashboardAssistant() {
			MixPanel.trackToAssistant;
		},
	},
};
</script>

<style scoped lang="scss">
#menu-nav-mobile {
	a {
		&:not(:last-child) {
			border-right: 1px solid #989fa9;
		}
	}
	a {
		color: #989fa9 !important;
	}
	a:hover {
		color: #686bdf !important;
	}

	.navBarLink {
		font-weight: 500;
		letter-spacing: 0.0892857143em;
		text-transform: uppercase;
		font-size: 12px;
	}

	.purpleColor {
		color: #686bdf !important;
	}

	.burger-container {
		display: flex;
		.nav-menu {
			min-width: 180px;
			background-color: white;
			padding: 16px;
			position: absolute;
			right: 16px;
			top: 60px;

			.v-list-item {
				display: flex;
				gap: 8px;
			}

			.v-list-item__title {
				display: flex;
				width: 100%;
			}

			.v-list-item::after {
				display: none;
			}
		}
	}
}
</style>
